import {
  Box,
  Button,
  Grid,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
  Heading,
  useDisclosure
} from '@chakra-ui/react';
import { ROLES } from '../../constants';
import { setAlert } from '../../store/actions/alertActions';
import { FcCollapse } from 'react-icons/fc';
import { FaSkullCrossbones } from 'react-icons/fa6';
import { FaChevronDown } from 'react-icons/fa';
import useAuthRole from '../../hooks/useAuthRole';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import CopyableText from '../CopyableText';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import {
  getFalconTransactionTable,
  getRecipientTable,
  getPayoutTable,
} from './utils';
import {
  forceFailTransaction,
  getForceStatusSync
} from '../../store/actions/falconDashboardActions';
import DetailsTable from '../OrderDetailsCard/DetailsTable';
import { FaSync } from 'react-icons/fa';
import ForceFailTransactionModal from '../../components/Modal/ForceFailTransactionModal';
import {formatNumberWithCommas} from "../../utils";

const FalconTransactionCard = ({
  onOrderDetailsClick,
  falconTransaction,
  recipient,
  payout,
  show,
  isSidebar = false,
  configs,
  setRenderTrigger,
  selected
}) => {
  const {
    isOpen: isConfirmSyncModalOpen,
    onOpen: onConfirmSyncModalOpen,
    onClose: onConfirmSyncModalClose
  } = useDisclosure();
  const {
    isOpen: isForceFailModalOpen,
    onOpen: onForceFailModalOpen,
    onClose: onForceFailModalClose
  } = useDisclosure();
  
  const dispatch = useDispatch();

  const { hasAnyAuthority } = useAuthRole();
  const [isTransactionCollapsed, setIsTransactionCollapsed] = useState(false);
  const [isRecipientCollapsed, setIsRecipientCollapsed] = useState(false);
  const [isPayoutCollapsed, setIsPayoutCollapsed] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const recipientObj = getRecipientTable(recipient) || null;
  const transactionObj = getFalconTransactionTable(falconTransaction);

  const handleForceStatusSync = ({ isConfirmed }) => {
    if (!isConfirmed) {
      onConfirmSyncModalOpen();
      return;
    }
    const latestPayoutId = payout[payout.length - 1]?.internalPayoutId; // must have one to be able to force sync or force fail
    setIsModalLoading(true);
    dispatch(
      getForceStatusSync({
        payoutId: latestPayoutId,
        onComplete: () => {
          setIsModalLoading(false);
          onConfirmSyncModalClose();
        }
      })
    );
  };
  const handleFailTxn = ({ data, isFail = false }) => {
    if (!isFail) {
      onForceFailModalOpen();
    } else {
      const latestPayoutId = payout[payout.length - 1]?.internalPayoutId;
      if (data?.comments === '' || data?.comments === undefined) {
        dispatch(
          setAlert({
            title: 'comments cannot be empty',
            status: 'error'
          })
        );
        return;
      }
      setIsModalLoading(true);
      const forceFailData = {
        ...data,
        payoutId: latestPayoutId
      };
      dispatch(
        forceFailTransaction({
          forceFailData,
          onComplete: () => {
            setIsModalLoading(false);
            onForceFailModalClose();
          }
        })
      );
    }
  };
  return (
    <Box position="relative" width={!isSidebar ? '100%' : '450px'}>
      {configs && (
        <ModalLayout
          isOpen={isForceFailModalOpen}
          onClose={onForceFailModalClose}
          textAlign="center"
          size={'md'}
        >
          <ForceFailTransactionModal
            onSubmit={(data) => {
              handleFailTxn({
                isFail: true,
                data
              });
            }}
            onCancel={onForceFailModalClose}
            isLoading={isModalLoading}
            listOfAvailableClients={configs?.payoutPartners}
          />
        </ModalLayout>
      )}

      <ModalLayout
        isOpen={isConfirmSyncModalOpen}
        onClose={() => {
          onConfirmSyncModalClose();
        }}
        textAlign="center"
        size={'md'}
      >
        <ConfirmModal
          handleSubmit={() => {
            handleForceStatusSync({ isConfirmed: true });
          }}
          prompt={'Are you sure you want to force sync this transaction ?'}
          isLoading={isModalLoading}
        />
      </ModalLayout>
      <VStack
        bg={!isSidebar ? 'backGround' : undefined}
        borderRadius="12px"
        divider={<StackDivider borderColor="whiteAlpha.300" />}
        p={isSidebar ? 2 : 4}
        align="stretch"
        borderLeft={selected && '2px'}
        borderColor={'brand.400'}
      >
        {isSidebar ? (
          <VStack alignItems="left">
            {/* Transaction Details */}
            <VStack align="left">
              <HStack justifyContent="space-between">
                <Heading fontSize="lg" color="brand.500">
                  Transaction Details
                </Heading>
                <Button
                  size="sm"
                  onClick={() =>
                    setIsTransactionCollapsed(!isTransactionCollapsed)
                  }
                  bg="transparent"
                  _hover={{ bg: 'transparent' }}
                >
                  {isTransactionCollapsed ? <FaChevronDown /> : <FcCollapse />}
                </Button>
              </HStack>
              {!isTransactionCollapsed &&
                transactionObj.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.data && (
                      <DetailsTable isSidebar={isSidebar} details={item.data} />
                    )}
                  </React.Fragment>
                ))}
            </VStack>

            {/* Recipient Details */}
            <VStack align="left">
              <HStack justifyContent="space-between">
                <Heading fontSize="lg" color="brand.500">
                  Recipient Details
                </Heading>
                <Button
                  size="sm"
                  onClick={() => setIsRecipientCollapsed(!isRecipientCollapsed)}
                  bg="transparent"
                  _hover={{ bg: 'transparent' }}
                >
                  {isRecipientCollapsed ? <FaChevronDown /> : <FcCollapse />}
                </Button>
              </HStack>
              {!isRecipientCollapsed &&
                recipientObj.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.data && (
                      <DetailsTable isSidebar={isSidebar} details={item.data} />
                    )}
                  </React.Fragment>
                ))}
            </VStack>

            {/* Payout Details */}
            <VStack align="left">
              <HStack justifyContent="space-between" mb={2}>
                <Heading fontSize="lg" color="brand.500">
                  Payout Details ({payout.length})
                </Heading>
                <Button
                  size="sm"
                  onClick={() => setIsPayoutCollapsed(!isPayoutCollapsed)}
                  bg="transparent"
                  _hover={{ bg: 'transparent' }}
                >
                  {isPayoutCollapsed ? <FaChevronDown /> : <FcCollapse />}
                </Button>
              </HStack>
              {!isPayoutCollapsed && payout.length > 0 && (
                <VStack
                  flex="1"
                  // maxHeight="400px" // Set max height for scrollable area
                  overflowY="auto" // Make the area scrollable
                  width="100%" // Ensure consistent width
                  spacing={4} // Add spacing between payout items
                >
                  {payout.map((payoutItem, index) => {
                    const payoutObj = getPayoutTable(payoutItem);
                    return (
                      <VStack
                        key={index}
                        bg="backGround" // Background color for clarity
                        p={4}
                        borderRadius="md"
                        align="left"
                        width="100%"
                      >
                        <Heading fontSize="lg" color="brand.500">
                          Payout {index + 1}
                        </Heading>
                        {payoutObj.map((item, idx) => (
                          <DetailsTable
                            key={idx}
                            isSidebar={true}
                            details={item.data}
                          />
                        ))}
                      </VStack>
                    );
                  })}
                </VStack>
              )}
              {hasAnyAuthority(
                ROLES.ADMIN_ROLE,
                ROLES.FALCON_ADMIN,
                ROLES.FALCON_OPS_MANAGER
              ) && (
                <>
                  {falconTransaction?.status === 'COMPLETED' && (
                    <Button
                      pb={4}
                      variant={'link'}
                      size={'sm'}
                      colorScheme="red"
                      rightIcon={<FaSkullCrossbones />}
                      onClick={() => {
                        handleFailTxn({
                          isFail: false
                        });
                      }}
                    >
                      Fail
                    </Button>
                  )}
                  {(falconTransaction?.status === 'COMPLETED' ||
                    falconTransaction?.status === 'PROCESSING') && (
                    <Button
                      variant={'link'}
                      size={'sm'}
                      colorScheme="red"
                      rightIcon={<FaSync />}
                      onClick={() => {
                        handleForceStatusSync({ isConfirmed: false });
                      }}
                    >
                      Force Sync
                    </Button>
                  )}
                </>
              )}
            </VStack>
          </VStack>
        ) : (
          <>
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: 'repeat(3fr, auto)'
              }}
            >
              <Stack>
                <HStack justifyContent={'space-between'} alignItems="start">
                  <VStack align={'start'}>
                    <HStack>
                      <Text fontSize="md" color="gray">
                        Transaction ID:
                      </Text>
                      <CopyableText
                        title="Transaction ID"
                        fontSize="md"
                        color="white"
                      >
                        {falconTransaction?.transactionId || '-'}
                      </CopyableText>
                    </HStack>
                    <HStack>
                      <Text fontSize="md" color="gray">
                        Client Transaction ID:
                      </Text>
                      <CopyableText
                        title="Client Transaction ID"
                        fontSize="md"
                        color="white"
                      >
                        {falconTransaction?.clientTxnId || '-'}
                      </CopyableText>
                    </HStack>
                  </VStack>
                  <Box p={4}>
                    <HStack spacing={2} alignItems="center">
                      <Box
                        w={3}
                        h={3}
                        borderRadius="full"
                        bg={
                          falconTransaction?.status === 'COMPLETED'
                            ? 'green.500'
                            : 'yellow.500'
                        }
                      />
                      <Text fontSize="md" fontWeight="bold" color="white">
                        {falconTransaction?.status}
                      </Text>
                    </HStack>
                  </Box>
                  <VStack>
                    <HStack>
                      <Text fontSize="md" color="gray">
                        Created At:
                      </Text>
                      <Text fontSize="md" color="white">
                        {new Date(
                          falconTransaction?.createdAt
                        ).toLocaleDateString() || 'N/A'}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="md" color="gray">
                        Updated At:
                      </Text>
                      <Text fontSize="md" color="white">
                        {new Date(
                          falconTransaction?.updatedAt
                        ).toLocaleDateString() || 'N/A'}
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>
              </Stack>
            </Grid>

            <Grid
              gridTemplateColumns="repeat(4, 1fr) auto"
              gap={10}
              alignItems="flex-start"
              w="100%"
              textAlign="start"
              paddingTop={'20px'}
            >
              <Stack direction="column" spacing={1}>
                <Text fontSize="md" color="gray">
                  Source Amount
                </Text>
                <Text fontSize="md" color="white">
                  {`${falconTransaction?.sourceAmount} ${falconTransaction?.sourceCurrency}`}
                </Text>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Text fontSize="md" color="gray">
                  Payout amount
                </Text>
                <Text fontSize="md" color="white">
                  {`${formatNumberWithCommas(falconTransaction?.payoutAmount)} ${falconTransaction?.payoutCurrency}`}
                </Text>
              </Stack>
              {show && (
                <Stack direction="column" spacing={1}>
                  <Text fontSize="md" color="gray">
                    {show
                      .replace(/([A-Z])/g, ' $1')
                      .split(' ')
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(' ')}
                  </Text>
                  <Text fontSize="md" color="white">
                    {falconTransaction[show] || '-'}
                  </Text>
                </Stack>
              )}
              <Stack direction="column" spacing={1}>
                <Text fontSize="md" color="gray">
                  Client
                </Text>
                <Text fontSize="md" color="white">
                  {falconTransaction?.client}
                </Text>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Text fontSize="md" color="gray">
                  Exchange Rate
                </Text>
                <Text fontSize="md" color="white">
                  {`${falconTransaction?.exchangeRate}`}
                </Text>
              </Stack>
              <Button
                size="md"
                rounded="md"
                color="black"
                colorScheme="brand"
                onClick={() => {
                  onOrderDetailsClick(falconTransaction?.transactionId);
                }}
              >
                Details
              </Button>
            </Grid>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default FalconTransactionCard;
