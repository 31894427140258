import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import {
  Box,
  VStack,
  Spinner,
  Text,
  Flex,
  Button,
  HStack,
  StackDivider,
  Heading,
  Input, FormLabel
} from '@chakra-ui/react';
import { CARD_TYPE, ROLES } from '../../../constants';
import GenericCard from '../../GenericCard';
import {
  createRefundSheet,
  downloadLuluRefundSheet,
  getLuluRefundSheets,
  processLuluRefundSheet, uploadLuluRefundSheet, uploadMarkCompleteRefundSheet
} from '../../../store/actions/luluRefundsActions';
import { FiCheckCircle, FiDownload, FiUpload } from 'react-icons/fi';
import { MdOutlineCreateNewFolder } from 'react-icons/md';
import useAuthRole from '../../../hooks/useAuthRole';
import { setAlert } from '../../../store/actions/alertActions';


const ManageRefunds = () => {
  const dispatch = useDispatch();
  const { hasAuthority } = useAuthRole();

  const {
    data: luluRefundSheetList,
    isLoading,
    isError,
    error,
  } = useSelector((state) => state?.luluRefundsDashboard?.luluRefundSheets);

  const [file, setFile] = useState(null);
  const [completedFile, setCompletedFile] = useState(null);

  const [isDownloadSheetLoading, setIsDownloadSheetLoading] = useState({});
  const [isProcessSheetLoading, setIsProcessSheetLoading] = useState({});
  const [isCompleteSheetLoading, setIsCompleteSheetLoading] = useState(false);

  // Fetch the data for the first time
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      getLuluRefundSheets({})
    );
  };

  const handleDownloadRefundSheet = (luluRefundSheetId) => {
    setIsDownloadSheetLoading((prev) => ({
      ...prev,
      [luluRefundSheetId]: true,
    }));

    dispatch(
      downloadLuluRefundSheet({
        sheetId: luluRefundSheetId,
        onSuccess: (data) => {
          const blob = new Blob([data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `REFUND_SHEET_${luluRefundSheetId}.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
        },
        onComplete: () => {
          setIsDownloadSheetLoading((prev) => ({
            ...prev,
            [luluRefundSheetId]: false,
          }));
        }
      })
    )
  }

  const handleMarkAsProcessed = (luluRefundSheetId) => {
    setIsProcessSheetLoading((prev) => ({
      ...prev,
      [luluRefundSheetId]: true,
    }));
    dispatch(
      processLuluRefundSheet({
        sheetId: luluRefundSheetId,
        onComplete: () => {
          setIsProcessSheetLoading((prev) => ({
            ...prev,
            [luluRefundSheetId]: false,
          }));
          fetchData();
        }
      })
    )
  }

  const handleSheetUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      const rawData = XLSX.utils.sheet_to_json(sheet);

      const formattedData = rawData
        .filter((row) => {
          const normalizedRow = Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key.toLowerCase().replace(/ /g, "_"), value])
          );
          return normalizedRow["txn_ref_no"];
        })
        .map((row) => {
          const normalizedRow = Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key.toLowerCase().replace(/ /g, "_"), value])
          );

          return {
            transaction_id: normalizedRow["txn_ref_no"] || "",
            description: normalizedRow["brn/description"] || "",
            transaction_amount: normalizedRow["txn_amount"] || "",
            reason: normalizedRow["reason"] || null,
          };
        });

      if(formattedData.length) {
        dispatch(uploadLuluRefundSheet({
          refundSheetDetails: formattedData,
        }));
      } else {
        dispatch(
            setAlert({
              title: "No valid data found in the uploaded file",
              status: "error"
            })
        );
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleMarkAsCompleted = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setCompletedFile(file);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      const rawData = XLSX.utils.sheet_to_json(sheet);

      const formattedData = rawData.filter((row) => {
        const normalizedRow = Object.fromEntries(
          Object.entries(row).map(([key, value]) => [key.toLowerCase().replace(/ /g, "_"), value])
        );
        return normalizedRow["transaction_id"];
      }).map((row) =>{
        const normalizedRow = Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key.toLowerCase().replace(/ /g, "_"), value])
        )
        return {
          transaction_id: normalizedRow["transaction_id"] || "",
          refund_status: normalizedRow["refund_status"] || "",
          bank_iban_number: normalizedRow["iban_no"] || "",
        }
      })

      if(formattedData.length) {
        dispatch(
            uploadMarkCompleteRefundSheet({
              completedSheetDetail: formattedData,
            })
        );
      } else {
        dispatch(
            setAlert({
              title: "No valid data found in the uploaded file",
              status: "error"
            })
        );
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleCreateRefundSheet = () => {
    setIsCompleteSheetLoading(true);
    dispatch(createRefundSheet({
      onComplete: () => {
        setIsCompleteSheetLoading(false);
        fetchData();
      }
    }));
  }

  return (
    <VStack
      overflowY={'hidden'}
      overflowX="hidden"
      alignItems="stretch"
      gap={4}
      px={4}
      divider={<StackDivider borderColor="whiteAlpha.300" />}
    >
      <HStack justifyContent="start" alignItems="center" gap={4}>
        <Heading size={"md"}> Manage Refunds </Heading>
      </HStack>

      {hasAuthority(ROLES.LULU_REFUND_MANAGER_ROLE) && (<HStack
        display="flex"
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        divider={<StackDivider borderColor="whiteAlpha.300" />}
      >
        {/*Upload File*/}
        <VStack
          alignItems="start"
          width={'auto'}
          spacing={0}
        >
          <FormLabel fontSize="sm" alignItems={'start'}>
            Upload New Refund Sheet
          </FormLabel>
          <HStack
            display="flex"
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={2}
          >
            <Box
              as="label"
              htmlFor="fileInput"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
              px={4}
              py={2}
              fontSize="sm"
              fontWeight="semibold"
              bg="#414141"
              color="white"
              borderRadius="lg"
              cursor="pointer"
              _hover={{
                color: 'colorPrimary',
                borderColor: 'colorPrimary'
              }}
              transition="all 0.3s ease-in-out"
            >
              <FiUpload size="1.1rem" />
              <Text fontSize={'xs'}>Upload</Text>
            </Box>
            <Input
              id="fileInput"
              type="file"
              accept=".xlsx,.xls"
              onChange={handleSheetUpload}
              display="none"
            />
            <Text
              fontSize="sm"
              color="whiteAlpha.800"
            >
              {file?.name || 'Accepts .xlsx, .xls files'}
            </Text>
          </HStack>
        </VStack>

        {/* Mark as completed sheet */}
        <VStack
          alignItems="start"
          width={'auto'}
          spacing={0}
        >
          <FormLabel fontSize="sm" alignItems={'start'}>
            Mark Refund(s) Completed
          </FormLabel>
          <HStack
            display="flex"
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={2}>
            <Box
              as="label"
              htmlFor="completedFile"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
              px={4}
              py={2}
              fontSize="sm"
              fontWeight="semibold"
              bg="#414141"
              color="white"
              borderRadius="lg"
              cursor="pointer"
              _hover={{
                color: 'colorPrimary',
                borderColor: 'colorPrimary'
              }}
              transition="all 0.3s ease-in-out"
            >
              <FiUpload size="1.1rem" />
              <Text fontSize={'xs'}>Upload</Text>
            </Box>
            <Input
              id="completedFile"
              type="file"
              accept=".xlsx,.xls"
              onChange={handleMarkAsCompleted}
              display="none"
            />
            <Text
              fontSize="sm"
              color="whiteAlpha.800"
            >
              {completedFile?.name || 'Accepts .xlsx, .xls files'}
            </Text>
          </HStack>
        </VStack>

        {/*Create Sheet*/}
        <VStack
          spacing={4}
          align="center"
          width={'auto'}
        >
          <Button
            gap={2}
            w={'full'}
            mt={1}
            fontWeight="semibold"
            fontSize="sm"
            bg="#414141"
            color="white"
            borderRadius="lg"
            border={'1px solid'}
            borderColor="whiteAlpha.500"
            cursor="pointer"
            _hover={{
              color: 'colorPrimary',
              borderColor: 'colorPrimary'
            }}
            transition="all 0.3s ease-in-out"
            onClick={() => handleCreateRefundSheet()}
            isLoading={isCompleteSheetLoading}
            isDisabled={isCompleteSheetLoading}
          >
            <MdOutlineCreateNewFolder size="1.1rem" />
            Create Refund Sheet
          </Button>
        </VStack>
      </HStack>
      )}

      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" w="full" h="full">
          <Spinner thickness="4px" size="lg" color="colorPrimary" />
        </Flex>
      ) : isError ? (
        <Box color="red.500" p={4} borderRadius="md" borderWidth="1px">
          <Text>An error occurred while fetching data: {error}</Text>
        </Box>
      ) : luluRefundSheetList?.length ? (
        <VStack
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX="hidden"
            alignItems="start"
            gap={4}>
          {luluRefundSheetList.map((item) => (
            <GenericCard
              key={item?.luluRefundSheetId}
              cardType={CARD_TYPE.LULU_REFUND_SHEET_CARD}
              cardData={item}
              maxW={'100%'}
              ActionButtons={[
                <Button
                  gap={2}
                  w={'20'}
                  mt={1}
                  fontWeight="semibold"
                  bg="#414141"
                  color="white"
                  borderRadius="lg"
                  border={'1px solid'}
                  borderColor="whiteAlpha.500"
                  cursor="pointer"
                  _hover={{
                    color: "colorPrimary",
                    borderColor: "colorPrimary",
                  }}
                  transition="all 0.3s ease-in-out"
                  onClick={() => handleDownloadRefundSheet(item?.luluRefundSheetId)}
                  isLoading={isDownloadSheetLoading[item?.luluRefundSheetId]}
                  isDisabled={isProcessSheetLoading[item?.luluRefundSheetId]}
                >
                  <FiDownload size="1.1rem" />
                </Button>,
                  hasAuthority(ROLES.LULU_REFUND_MANAGER_ROLE) ? (
                    <Button
                      gap={2}
                      mt={1}
                      colorScheme="brand"
                      color="black"
                      width="auto"
                      size="md"
                      fontSize="sm"
                      onClick={() => handleMarkAsProcessed(item?.luluRefundSheetId)}
                      isLoading={isProcessSheetLoading[item?.luluRefundSheetId]}
                      isDisabled={isProcessSheetLoading[item?.luluRefundSheetId]}
                    >
                      <FiCheckCircle size="1.1rem" />
                      Mark as Processed
                    </Button>
                  ) : null
              ]}
            />
          ))}
        </VStack>
      ) : (
        <Text color="white" fontSize="md">
          No Refund Sheet(s) found
        </Text>
      )}
    </VStack>
  );
};

export default ManageRefunds;
