import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Heading,
  HStack,
  Select,
  Spinner,
  Switch,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MdEdit } from 'react-icons/md';
import ModalLayout from '../Modal/ModalLayout';
import CreateClientModal from './CreateClientModal';
import UpdatePayoutPartner from './UpdatePayoutPartners';
import UpdateClientBalanceModal from './UpdateClientBalanceModal';
import { entityToClients } from '../../views/FalconServiceDashboard/utils';
import UpdateRFIValue from './UpdateRfiDocument';
import { ROLES } from '../../constants';
import useAuthRole from '../../hooks/useAuthRole';
const EntityDetails = ({ isSidebar = false, entity, config, client }) => {
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();
  const [payoutCurrency, setPayoutCurrency] = useState(null);
  const [payoutPartners, setPayoutPartners] = useState(null);
  const [clientBalanceModalData, setClientBalanceModalData] = useState({});
  const [rfiDocuments, setRfiDocuments] = useState(null);

  const {
    isOpen: isEditModalOpen,
    onClose: closeEditModal,
    onOpen: openEditModal
  } = useDisclosure();

  const {
    isOpen: isEditPartnerModalOpen,
    onClose: closeEditPartnerModal,
    onOpen: openEditPartnerModal
  } = useDisclosure();

  const {
    isOpen: isUpdateClientBalanceModalOpen,
    onClose: closeUpdateClientBalanceModal,
    onOpen: openUpdateClientBalanceModal
  } = useDisclosure();

  const {
    isOpen: isEditRIFValueOpen,
    onClose: closeEditRFIValueModal,
    onOpen: openEditRFIValueModal
  } = useDisclosure();

  useEffect(() => {
    if (entity) {
      setPayoutCurrency(
        entity.payoutCurrencies
          ? entity.payoutCurrencies[0]
          : entity.currency?.value
      );
    }
  }, [entity]);

  useEffect(() => {
    if (entity && payoutCurrency) {
      dispatch(
        config.fetchDetails({
          client: entity.name.value,
          currency: payoutCurrency
        })
      );
    } else if (entity && entity.requestId) {
      dispatch(
        config.fetchDetails({
          requestId: entity.requestId
        })
      );
    }
  }, [entity, payoutCurrency]);

  const handleEditClick = (edit, data) => {
    if (edit === 'editConfiguration') {
      openEditModal();
    } else if (edit === 'editPartners') {
      openEditPartnerModal();
      setPayoutPartners(data);
    } else if (edit === 'editClientBalance') {
      openUpdateClientBalanceModal();
      setClientBalanceModalData(entityToClients(data));
    }
  };

  const handleButtonClick = (func, details) => {
    setRfiDocuments(details);
    func({
      dispatch,
      openModal: openEditRFIValueModal
    });
  };

  return (
    <Box
      my={4}
      mr={1}
      px={'1rem'}
      maxH={isSidebar ? '85vh' : 'none'}
      className="scroll-sm"
      overflow={isSidebar ? 'auto' : 'inherit'}
    >
      {/*Create Edit Modal using Config*/}
      <ModalLayout
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        size="xl"
        scrollBehavior="inside"
      >
        <CreateClientModal
          clientDetails={client.data}
          onClose={closeEditModal}
          config={config}
        />
      </ModalLayout>

      {/*Update Client Balance Modal*/}
      <ModalLayout
        isOpen={isUpdateClientBalanceModalOpen}
        onClose={closeUpdateClientBalanceModal}
        size="md"
        scrollBehavior="inside"
      >
        <UpdateClientBalanceModal
          clientBalanceData={clientBalanceModalData}
          fetchDetails={() =>
            dispatch(
              config.fetchDetails({
                client: entity.clientName,
                currency: payoutCurrency
              })
            )
          }
          onClose={closeUpdateClientBalanceModal}
        />
      </ModalLayout>

      {/*Update Payout Partner Modal*/}
      <ModalLayout
        isOpen={isEditPartnerModalOpen}
        onClose={closeEditPartnerModal}
        size="xl"
        scrollBehavior="inside"
      >
        <UpdatePayoutPartner
          partners={payoutPartners}
          fetchDetails={() =>
            dispatch(
              config.fetchDetails({
                client: entity.clientName,
                currency: payoutCurrency
              })
            )
          }
          onClose={closeEditPartnerModal}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={isEditRIFValueOpen}
        onClose={closeEditRFIValueModal}
        size="xl"
        scrollBehavior="inside"
      >
        <UpdateRFIValue
          documents={rfiDocuments}
          onClose={closeEditRFIValueModal}
        />
      </ModalLayout>

      {entity.payoutCurrencies && (
        <HStack justifyContent={'flex-end'}>
          <Select
            w={'max-content'}
            onChange={(e) => setPayoutCurrency(e.target.value)}
          >
            {entity.payoutCurrencies.map((currency) => (
              <option value={currency}>{currency}</option>
            ))}
          </Select>
        </HStack>
      )}
      {client.isLoading ? (
        <Spinner color="brand.400" size="md" />
      ) : client.isError ? (
        <VStack align={'stretch'}>
          <Text color="red.500">
            Error fetching client details: Error - {client.error}
          </Text>
        </VStack>
      ) : (
        client.data?.length > 0 &&
        client.data.map((details, idx) => (
          <VStack align={'stretch'} gap={3} key={idx}>
            <HStack justify={'space-between'} pt={2}>
              <Heading size={'md'} color={'white'} mt={2}>
                {details.heading}
              </Heading>
              {details.allowSeparateEdit &&
                hasAnyAuthority(
                  ROLES.ADMIN_ROLE,
                  ROLES.FALCON_ADMIN,
                  ROLES.FALCON_OPS_MANAGER
                ) && (
                  <Button
                    colorScheme="brand"
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => {
                      handleEditClick(details.allowSeparateEdit, details.data);
                    }}
                  >
                    <MdEdit />
                  </Button>
                )}
            </HStack>
            <VStack align={'stretch'} gap={2}>
              {details.data?.map((detail, idx) => {
                return (
                  <>
                    {details.type === 'object' && (
                      <Grid key={idx} gridTemplateColumns={'2fr 3fr'}>
                        <Text size={'sm'} color={'gray'}>
                          {detail.title}:
                        </Text>
                        {(detail.type === 'text' ||
                          detail.type === 'select') && (
                          <Text size={'sm'} color={detail?.color || 'white'}>
                            {detail?.maskValue || detail?.value || 'N/A'}
                          </Text>
                        )}
                        {detail.type === 'bool' && (
                          <Checkbox
                            isDisabled
                            size={'md'}
                            defaultChecked={detail.value}
                            colorScheme="brand"
                            color={'white'}
                          />
                        )}
                        {detail.type === 'switch' && (
                          <Switch
                            isDisabled
                            size={'md'}
                            defaultChecked={detail.value}
                            colorScheme="brand"
                            color={'white'}
                          />
                        )}
                      </Grid>
                    )}
                    {details.type === 'list' && (
                      <HStack>
                        <Text size={'sm'} color={'gray'}>
                          {idx + 1}.
                        </Text>
                        <Text size={'sm'} color={'white'}>
                          {detail}
                        </Text>
                      </HStack>
                    )}
                    {details.type === 'cards' && (
                      <VStack
                        bg="backGround"
                        w={'full'}
                        px={4}
                        py={2}
                        borderRadius="12px"
                        alignItems={'flex-start'}
                      >
                        {Object.keys(detail).map((key, id) => (
                          <Grid
                            key={id}
                            w={'full'}
                            gridTemplateColumns={'2fr 3fr'}
                          >
                            <Text size={'sm'} color={'gray'}>
                              {detail[key].title}
                            </Text>
                            <Text size={'sm'} color={'white'} wordBreak={'break-word'}>
                              {detail[key]?.maskValue || detail[key].value}
                            </Text>
                          </Grid>
                        ))}
                      </VStack>
                    )}
                  </>
                );
              })}
            </VStack>

            {details.buttons && (
              <VStack gap={1}>
                {details.buttons.map(
                  (button, id) =>
                    button.condition && (
                      <Button
                        key={id}
                        w={'full'}
                        onClick={() =>
                          handleButtonClick(button.onClick, details.data)
                        }
                        color={button.color || 'black'}
                        colorScheme={button.colorScheme || 'brand'}
                        variant={button.variant || 'solid'}
                      >
                        {button.title}
                      </Button>
                    )
                )}
              </VStack>
            )}
            {idx !== client.data.length - 1 && <Divider />}
          </VStack>
        ))
      )}
    </Box>
  );
};

export default EntityDetails;
