import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Grid,
  Heading,
  VStack,
  Spinner,
  Text,
  Flex,
  Button,
  HStack,
  FormLabel,
  Select, StackDivider, Divider
} from '@chakra-ui/react';

import { MdSearch } from 'react-icons/md';

import ContentLayout from '../../layouts/ContentLayout';
import GenericCard from '../../components/GenericCard';
import Pagination from '../../components/Pagination';
import InputComponent from '../../components/Input';

import {
  BENEFICIARY_FILTER_TYPE,
  BENEFICIARY_SEARCH_KEY_TYPE,
  CARD_TYPE,
} from '../../constants';

import {
  getBeneficiaryVerifications
} from '../../store/actions/beneficiaryVerificationActions';
import { updateBeneficiaryDashboardReqData } from '../../store/actions/reqDataActions';

import { kebabCaseToSpaceSeperate } from '../../utils';
import useDidMountEffect from '../../hooks/useDidMount';

const RecipientVerificationDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchTimer = useRef(null);

  const searchReqData = useSelector((state) => state?.reqData?.beneficiaryVerificationDashboard);
  const { searchKey, searchValue, pageSize, pageNumber, filterType, totalPages } = searchReqData;
  const {
    data: beneficiaries,
    isLoading,
    isError,
    error,
  } = useSelector((state) => state?.beneficiaryVerificationDashboard?.beneficiaryVerifications);

  // Re-fetch the beneficiary data on change in searchValue
  useDidMountEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }
    searchTimer.current = setTimeout(() => {
      searchBeneficiaryVerifications(true);
    }, 2000);
  }, [searchValue]);

  // Re-fetch the beneficiary data on change in filters
  useDidMountEffect(() => {
    searchBeneficiaryVerifications(true);
  }, [filterType, searchKey, pageNumber]);

  // Fetch the data for the first time
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      getBeneficiaryVerifications({
        payload: {
          searchKey,
          searchValue,
          pageSize,
          pageNumber,
          filterType,
        },
        onSuccess: (beneficiaryData) => {
          const { totalPage, currentPageNumber, pageSize } = beneficiaryData;
          dispatch(
            updateBeneficiaryDashboardReqData({
              ...searchReqData,
              pageNumber: currentPageNumber,
              pageSize: pageSize,
              totalPages: totalPage,
            })
          );
        },
      })
    );
  };

  const handleReqDataChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    dispatch(
      updateBeneficiaryDashboardReqData({
        ...searchReqData,
        [key]: value,
      })
    );
  };

  const handleSearchReqDataChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value === searchValue) return;
      dispatch(
        updateBeneficiaryDashboardReqData({
          ...searchReqData,
          searchValue: value,
          pageNumber: 0,
        })
      );
    },
    [dispatch, searchReqData, searchValue]
  );

  const handlePageChange = (page) => {
    const newPageNumber = Math.max(0, page - 1);
    dispatch(
      updateBeneficiaryDashboardReqData({
        ...searchReqData,
        pageNumber: newPageNumber,
      })
    );
  };

  const navigateToBeneficiaryVerificationDetails = (verificationId) => {
    navigate(`/beneficiary-verification-dashboard/${verificationId}`);
  };

  const searchBeneficiaryVerifications = async (refresh = false) => {
    if (refresh || !beneficiaries?.length) {
      fetchData();
    }
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0 3fr' }}>
        <VStack
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX="hidden"
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems="stretch"
          gap={4}
          divider={<StackDivider borderColor="whiteAlpha.300" />}
        >
          <Heading size="lg" color="colorPrimary">
            Beneficiary Verification Dashboard
          </Heading>

          {/* Filters */}
          <VStack justifyContent="stretch" alignItems="start" gap={2}>
            <HStack justifyContent="start" alignItems="center" gap={4}>
              <VStack alignItems="stretch" spacing={0}>
                <FormLabel>Search Query Type</FormLabel>
                <Select
                  value={searchKey}
                  name="searchKey"
                  placeholder="Select Search Key Type"
                  onChange={handleReqDataChange}
                >
                  {Object.keys(BENEFICIARY_SEARCH_KEY_TYPE)?.map((key) => (
                    <option key={key} value={key}>
                      {kebabCaseToSpaceSeperate(key)}
                    </option>
                  ))}
                </Select>
              </VStack>
              <VStack alignItems="start" gap={2}>
                <InputComponent
                  type="text"
                  value={searchValue}
                  name="search"
                  handleChange={handleSearchReqDataChange}
                  placeholder={`Search beneficiary by ${kebabCaseToSpaceSeperate(searchKey)}...`}
                  inputTitle="Search beneficiary"
                  leftElement={<MdSearch size="2rem" />}
                  width="40rem"
                  maxWidth="100%"
                  autoComplete="off"
                  spellCheck={false}
                />
              </VStack>
            </HStack>
            <VStack alignItems="stretch" spacing={0}>
              <FormLabel>Filter Type</FormLabel>
              <Select
                value={filterType}
                name="filterType"
                placeholder="Select Filter Type"
                onChange={handleReqDataChange}
              >
                {Object.keys(BENEFICIARY_FILTER_TYPE)?.map((type) => (
                  <option key={type} value={type}>
                    {kebabCaseToSpaceSeperate(type)}
                  </option>
                ))}
              </Select>
            </VStack>
            <Button
              color="black"
              rounded="lg"
              colorScheme="brand"
              py={2}
              onClick={() => searchBeneficiaryVerifications()}
              fontWeight={600}
              isLoading={isLoading}
              alignSelf="start"
              mt={4}
            >
              Search
            </Button>
          </VStack>

          {/* Pagination */}
          <Pagination
            currentPage={pageNumber + 1}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />

          {/* Content */}
          {isLoading ? (
            <Flex justifyContent="center" alignItems="center" w="full" h="full">
              <Spinner thickness="4px" size="lg" color="colorPrimary" />
            </Flex>
          ) : isError ? (
            <Box color="red.500" p={4} borderRadius="md" borderWidth="1px">
              <Text>An error occurred while fetching data: {error}</Text>
            </Box>
          ) : beneficiaries?.length ? (
            <VStack alignItems="start" gap={2}>
              {beneficiaries.map((item) => (
                <GenericCard
                  key={item.verificationId}
                  cardType={CARD_TYPE.BENEFICIARY_CARD}
                  cardData={item}
                  ActionButtons={[
                    <Button
                      colorScheme="brand"
                      color="black"
                      width="auto"
                      size="md"
                      fontSize="sm"
                      mt={3}
                      onClick={() => navigateToBeneficiaryVerificationDetails(item.verificationId)}
                    >
                      Details
                    </Button>,
                  ]}
                />
              ))}
            </VStack>
          ) : (
            <Text color="white" fontSize="md">
              No beneficiaries found
            </Text>
          )}
        </VStack>
        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        />
      </Grid>
    </ContentLayout>
  );
};


export default RecipientVerificationDashboard;
