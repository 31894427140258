import {
  Button,
  Grid,
  Heading,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { AiOutlineFile } from 'react-icons/ai';
import { kebabCaseToSpaceSeperate, toSentenceCase } from '../../utils';
import { RFI_ITEMS, RFI_STATUS } from '../../views/RFITransactions/constants';
import api from '../../api';
import { FaTimes } from 'react-icons/fa';
import { getRFIStatusColor } from '../../views/RFITransactions/utils';
import RejectModal from './RejectModal';
import ModalLayout from '../Modal/ModalLayout';

const RFIDocButton = ({ text, url, activeFile, isLoading, onClick }) => {
  return (
    <Button
      variant={'link'}
      textDecoration={'underline'}
      color="white"
      rightIcon={<AiOutlineFile />}
      fontSize={'0.9rem'}
      fontWeight={400}
      onClick={() => onClick(url)}
      isLoading={activeFile == url && isLoading}
      isDisabled={!url}
      justifySelf={'flex-start'}
    >
      {text}
    </Button>
  );
};

const ShowStatus = ({ status, onRejectClick }) => {
  if (status === RFI_STATUS.SUBMITTED) {
    return (
      <Button
        variant={'outline'}
        colorScheme="red"
        isRound={true}
        w={'fit-content'}
        leftIcon={<FaTimes />}
        justifySelf={'center'}
        onClick={onRejectClick}
      >
        REJECT
      </Button>
    );
  } else {
    return (
      <HStack justifySelf={'center'}>
        <Icon viewBox="0 0 200 200" color={getRFIStatusColor(status)} w="8px">
          <path
            fill="currentColor"
            d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
          />
        </Icon>
        <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
          {status}
        </Text>
      </HStack>
    );
  }
};

export const RequestedItem = ({
  item,
  rfiData,
  setRequestedItems,
  needOverallReason
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeFile, setActiveFile] = useState(null);
  const [isBackSide, setIsBackSide] = useState(false);
  const [rejectedDocumentHeading, setRejectedDocumentHeading] = useState('');

  const {
    isOpen: isRejectModalOpen,
    onOpen: onRejectModalOpen,
    onClose: onRejectModalClose
  } = useDisclosure();

  const onFileClick = (url) => {
    setActiveFile(url);
    setIsLoading(true);
    api.workflowsV2
      .getDocByUrl({ userId: rfiData?.userId, docUrl: url })
      .then(({ data }) => window.open(data?.url, '_blank'))
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(true);
        setActiveFile(null);
      });
    return;
  };

  const handleRejectClick = (isBack, item) => {
    let text = `${item.requestedFor}: ${item.requestedInfo}`;
    if (item.value.inputType === RFI_ITEMS.DOCUMENT) {
      if (isBack) text += ' Back Side';
      else text += ' Front Side';
    }
    setRejectedDocumentHeading(text);
    onRejectModalOpen();
    setIsBackSide(isBack);
  };

  const handleRejectItem = (reason) => {
    setRequestedItems((prevState) =>
      prevState.map((obj) => {
        if (
          obj.requestedFor === item.requestedFor &&
          obj.requestedInfo === item.requestedInfo
        ) {
          const updatedValue = { ...obj.value };
          const newObj = { ...obj };
          if (obj.value.inputType === RFI_ITEMS.TEXT) {
            updatedValue.status = RFI_STATUS.REJECTED;
            updatedValue.rejectionReason = reason;
            updatedValue.frontStatus = RFI_STATUS.REJECTED;
            updatedValue.frontRejectionReason = reason;
            newObj.status = RFI_STATUS.REJECTED;
            newObj.rejectionReason = reason;
            newObj.frontStatus = RFI_STATUS.REJECTED;
            newObj.frontRejectionReason = reason;
          } else if (obj.value.inputType === RFI_ITEMS.DOCUMENT) {
            if (isBackSide) {
              updatedValue.backStatus = RFI_STATUS.REJECTED;
              updatedValue.backRejectionReason = reason;
              newObj.backStatus = RFI_STATUS.REJECTED;
              newObj.backRejectionReason = reason;
            } else {
              updatedValue.frontStatus = RFI_STATUS.REJECTED;
              updatedValue.frontRejectionReason = reason;
              newObj.frontStatus = RFI_STATUS.REJECTED;
              newObj.frontRejectionReason = reason;
            }
          }

          return { ...newObj, value: updatedValue };
        }

        return obj;
      })
    );
    needOverallReason(true);
    onRejectModalClose();
  };

  return (
    <>
      <ModalLayout
        size="2xl"
        isOpen={isRejectModalOpen}
        onClose={onRejectModalClose}
      >
        <RejectModal
          handleReject={handleRejectItem}
          rejectionHeading={rejectedDocumentHeading}
        />
      </ModalLayout>
      <Grid
        gridTemplateColumns={'2fr 4fr'}
        bg={'altGray.400'}
        p={6}
        w={'full'}
        borderRadius={'1rem'}
      >
        <HStack>
          <Text size={'md'}>
            {toSentenceCase(kebabCaseToSpaceSeperate(item.requestedFor))}:
          </Text>
          <Heading size={'sm'}>
            {toSentenceCase(kebabCaseToSpaceSeperate(item.requestedInfo))}
          </Heading>
        </HStack>
        <HStack flexGrow={1}>
          {item.value &&
            (item.value.inputType === RFI_ITEMS.TEXT ? (
              <Grid
                gridTemplateColumns={'2fr 1fr'}
                w={'full'}
                alignItems={'center'}
              >
                <Text color={'white'} size={'md'}>
                  {item.value.value || '-'}
                </Text>
                <ShowStatus
                  status={item.value.frontStatus || item.value.status}
                  onRejectClick={() => handleRejectClick(false, item)}
                />
              </Grid>
            ) : (
              item.value.inputType === RFI_ITEMS.DOCUMENT && (
                <VStack gap={2} align={'flex-start'} w={'full'}>
                  <Grid
                    gridTemplateColumns={'2fr 1fr'}
                    w={'full'}
                    alignItems={'center'}
                  >
                    <RFIDocButton
                      url={item.value?.frontValue}
                      text={'Open Front Side'}
                      isLoading={isLoading}
                      activeFile={activeFile}
                      onClick={onFileClick}
                    />
                    <ShowStatus
                      status={item.value.frontStatus}
                      onRejectClick={() => handleRejectClick(false, item)}
                    />
                  </Grid>
                  <Grid
                    gridTemplateColumns={'2fr 1fr'}
                    w={'full'}
                    alignItems={'center'}
                  >
                    <RFIDocButton
                      url={item.value?.backValue}
                      text={'Open Back Side'}
                      isLoading={isLoading}
                      activeFile={activeFile}
                      onClick={onFileClick}
                    />
                    <ShowStatus
                      status={item.value.backStatus}
                      onRejectClick={() => handleRejectClick(true, item)}
                    />
                  </Grid>
                </VStack>
              )
            ))}
        </HStack>
      </Grid>
    </>
  );
};